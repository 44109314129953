$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.systemLog {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
  overflow: hidden;

  .headerInfo {
    color: $secondary-600;
    font-size: 0.875rem;
  }

  .logSkeleton {
    background-color: lightblue;
    display: flex;

    .content {
      width: 20rem;
    }
  }

  .logItem {
    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 1rem;

    &::before {
      content: '';
      position: absolute;
      height: 160%;
      top: 1rem;
      left: 1.2rem;
      border-left: 1px solid $secondary-200;
      z-index: 1;
    }

    &:last-child {
      &::before {
        height: 0%;
      }
    }

    .avatar {
      margin-top: 0.5rem;
      z-index: 2;

      .systemIcon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        background-color: $secondary-100;
      }
    }

    .logSubject {
      display: flex;
      gap: 0.25rem;
      font-weight: 400;
      color: $secondary-900;

      strong {
        font-weight: 500;
        color: $secondary-900;
      }

      .author {
        font-weight: 500;
      }
    }

    .date {
      color: $secondary-600;
      font-weight: 400;
      font-size: 0.875rem;
    }
  }
}

.loadMoreButton {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2.5rem;
}

.pagination {
  margin-top: 2.5rem;
}
