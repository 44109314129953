$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.accordion {
  color: $secondary-900;

  .accordionHeader {
    display: flex;
    gap: 0.5rem;
    cursor: pointer;

    h1 {
      font-size: 1.125rem;
      font-weight: 600;
    }
  }

  .accordionBody {
    background-color: $secondary-50;
    color: $secondary-900;
    margin-top: 1rem;
    padding: 1rem;
    padding-left: 1.9rem;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    font-weight: 400;

    ul {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-bottom: 0;
    }
  }
}
