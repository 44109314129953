$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.orderMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 1rem;
  height: 100%;
  border-right: 1px solid $secondary-200;
  font-weight: 400;
  overflow-y: scroll;

  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding: 1rem;
    margin-bottom: 0.5rem;
    border-radius: $border-radius-sm;
    max-width: 100%;
    overflow: hidden;
    transition: background 0.3s;
    cursor: pointer;

    &.active {
      background-color: $secondary-100;
    }

    &:hover {
      background-color: $secondary-200;
    }

    .icon {
      margin-right: 0.5rem;
    }

    .id {
      color: $secondary-600;
    }

    .info {
      display: flex;
      gap: 0.5rem;
    }
  }

  .subtasksContainer {
    margin-left: 1rem;
    padding-left: 0.5rem;
    border-left: 1px solid $secondary-200;
  }

  hr.divider {
    border: 1px solid $secondary-200;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .footer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
