$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

thead.tableHeader {
  tr.filters {
    background-color: $secondary-100;

    th {
      border: 0;
      padding-top: 1rem;
      padding-bottom: 1rem;
      vertical-align: middle;
      &::before {
        width: 0px;
      }

      &:first-of-type {
        border-bottom-left-radius: $border-radius-md;
        border-top-left-radius: $border-radius-md;
        padding-left: 0.75rem;
      }

      &:last-of-type {
        border-bottom-right-radius: $border-radius-md;
        border-top-right-radius: $border-radius-md;
        padding-right: 0.75rem;
      }

      &.idFilter {
        width: 6%;
      }

      &.nameFilter {
        width: 25%;
      }

      &.languageQualificationFilter {
        width: 30%;

        .multipleColumnHeader {
          display: flex;
          flex-direction: row;
          gap: 0.75rem;

          > div {
            width: 100%;
          }
        }
      }

      &.statusFilter {
        width: 10%;
      }

      &.dateFilter {
        width: 10%;
      }
    }
  }

  tr.headers {
    th {
      font-weight: 600;
      color: $secondary-600;
    }
  }
}

.emptyStateContainer {
  margin: 6rem auto;
}
