$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.orderEmails {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .overview {
    background-color: $secondary-100;
    border-radius: $border-radius-md;
    padding: 1rem;

    .id {
      color: $secondary-600;
      font-size: 0.875rem;
      font-weight: 400;
    }

    .title {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 1.125rem;
      font-weight: 600;
    }

    .description {
      display: block;
      color: $secondary-600;
      font-size: 0.875rem;
      font-weight: 400;
      margin-top: 1rem;
    }
  }

  .detailsItemDetails {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .emailAddressesHeader {
    color: $secondary-600;
    font-weight: 400;
  }

  .emailAddresses {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .emails {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .header {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: $secondary-900;
      font-size: 1.125rem;
    }
  }

  .emailIndicator {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    width: 2.5rem;
    background-color: $secondary-100;
    color: $secondary-900;
    border-radius: 50%;

    &.sent {
      background-color: $success-100;
      color: $success-700;
    }
  }
}
