$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.regexHint {
  margin-top: 0.25rem;
  display: flex;
  gap: 0.125rem;

  > svg {
    margin-top: 0.125rem;
  }
}

.hintText {
  font-size: 0.875rem;
  color: $secondary-600;
  font-weight: 400;
  margin-bottom: 0;
}

.warning {
  color: $warning-600;
}

.success {
  color: $success-600;
}
