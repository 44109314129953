$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.sendSavedResponse {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .selectRowLabel {
    display: block;
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
    font-weight: 400;
    color: $secondary-900;
  }

  .selectRow {
    display: flex;
    gap: 1rem;
    width: 100%;
  }
  .selectRow > div {
    width: 100%;
  }

  .newResponseButton {
    min-width: 30%;
  }

  .dropdownButton {
    width: 100%;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
}
