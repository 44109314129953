$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.sectionContainer {
  width: 17.5rem;

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  .sectionName {
    padding-bottom: 1rem;
    color: $secondary-500;
    border-bottom: 1px solid $secondary-200;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.04em;
  }

  .itemsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-auto-flow: column;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    margin-top: 1rem;
  }
}
