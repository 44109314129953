$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

.assignmentSearch {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;

  .searchIcon {
    &.expanded {
      display: none;
    }
  }

  .searchForm {
    width: 0;
    transition: width 0.3s ease-in-out, border-width 0.3s ease-in-out, padding 0.3s ease-in-out;

    input {
      padding: 0;
      border-width: 0;
    }

    &.expanded {
      width: 10rem;
      input {
        padding: 0.5rem;
        border-width: 1px;
      }
    }
  }
}
