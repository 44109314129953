$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.settingsSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.sectionItemContent {
  margin-top: 2rem;
  
  .errorMessage {
    color: $error-600;
    display: block;
  
    & > :not(:first-child) {
      margin-left: 0.25rem;
    }
  }
}

.emailInputRow {
  display: flex;
  width: 100%;
  gap: 1rem;
}

.emailInput {
  width: 50%;
}

.emailList {
  padding-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.emailItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}


.accessLevel {
  width: 30%;
}
