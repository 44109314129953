$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.settingsSection {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.sectionItemContent {
  margin-top: 2rem;
}

.gender {
  display: flex;
  gap: 2rem;
}

.radioItem {
  padding: 0.75rem;
}

.additionalInfo {
  display: block;
  margin-bottom: 1.5rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $secondary-600;
}

.languages {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.radioLabel {
  display: block;
  margin-top: 1.5rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $secondary-600;
  margin-bottom: 1rem;
}

.gridRow {
  display: grid;
  grid-template-columns: 1fr 2fr 0.6fr;
  width: 50%;
  gap: 1rem;
}

.errorMessage {
  color: $error-600;
  display: flex;
  gap: 0.25rem;
  font-size: 0.875rem;
  font-weight: 400;
}

.fileSection {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.fileList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.specificInterpreterWarning {
  display: flex;
  gap: 0.5rem;
  font-weight: 400;
  margin-top: 0.75rem;
  font-size: 0.875rem;
}

.exclamationIcon {
  color: $warning-600;
}

.availabilityTimelineWrapper {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
  padding: 2rem;
  border: 1px solid $secondary-200;
  border-radius: 0.25rem;

  .availabilityTimelineLabel {
    display: block;
    color: $secondary-600;
    font-weight: 400;
    font-size: 0.875rem;
  }
}
