$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.editBooker {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .changeOwnerButton {
    margin-top: 1rem;
    width: 100%;
  }

  .phoneInputs {
    display: flex;
    align-items: flex-start;
    gap: 2rem;

    & > * {
      width: 100%;
    }
  }

  .phoneInputs {
    & > :first-child {
      max-width: 7rem;
    }
  }
}
.actions,
.changeOwnerActions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.changeOwnerActions {
  padding-top: 2.5rem;
}

.horizontalLine {
  margin: 0;
  color: $secondary-200;
}
