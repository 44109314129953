$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.container {
  padding-inline: 2rem;
  padding-top: 3rem;
  padding-bottom: 2rem;

  h1 {
    font-size: 2.25rem;
    color: $secondary-900;
    margin: unset;
  }

  .header {
    display: flex;
    justify-content: space-between;
  }

  .tabsContainer {
    display: flex;
    justify-content: space-between;
    padding-top: 3rem;
    border-bottom: 1px solid $secondary-200;
  }

  .tableContainer {
    margin-top: 1rem;
    overflow-x: scroll;
    overflow: visible;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .loadMoreButton {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
  }
}
