$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.label {
  display: block;
  margin-bottom: 0.25rem;
  color: $secondary-900;
  font-size: 14px;
  font-weight: 400;
}

.radioOptions {
  display: flex;
  width: 100%;
  gap: 1rem;

  .option {
    display: flex;
    justify-content: flex-start;
    border-radius: $border-radius-sm;
    padding: 0.875rem;
    background-color: $white;
    width: 100%;
    min-width: 0;

    &:disabled {
      background-color: $white;
      border-color: $secondary-400;
      opacity: 1;
    }

    &.block {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      width: 14rem;
      max-width: 14rem;
      text-wrap: wrap;
    }

    &.selected {
      box-shadow: 0 0 0px 1px $secondary-900 inset;
      border-color: $secondary-900;
    }

    .optionContent {
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &.block {
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 1rem;

        .selectedIndicator {
          margin-left: 0rem;
          margin-right: 1rem;
        }
      }

      .optionText {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
        text-align: left;
        width: 100%;
        min-width: 0;

        .titleContainer {
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          width: 100%;

          .title {
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.5rem;
          }

          .defaultBadge {
            height: fit-content;
            border: 1px solid $secondary-300;
            border-radius: $border-radius-sm;
            font-size: 0.875rem;
            padding: 2px 4px;
            margin-left: 0.5rem;
          }
        }

        .subtitle {
          font-weight: 400;
          color: $secondary-600;
        }

        .description {
          font-size: 0.865rem;
          font-weight: 400;
          color: $secondary-600;
          margin-top: 0.5rem;
        }
      }

      .selectedIndicator {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 1rem;
        border: 1px solid $secondary-900;
        width: 1.25rem;
        height: 1.25rem;
        min-width: 1.25rem;
        min-height: 1.25rem;
        border-radius: 50%;

        &--disabled {
          background-color: $secondary-300;
          border: 1px solid $secondary-400;
        }

        &.selected {
          background-color: $secondary-900;
        }

        .selectedIcon {
          font-size: 0.75rem;
          color: white;
        }
      }
    }
  }
}

small.errorMessage {
  color: $error-600;
  display: block;

  & > :not(:first-child) {
    margin-left: 0.25rem;
  }
}
