$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.itemRow {
  color: $secondary-900;

  td {
    vertical-align: middle;

    span {
      display: inline-flex;
      align-items: center;
      white-space: nowrap;
    }
  }

  .subtaskId {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  .status {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  .statusTooltip {
    display: flex;
    gap: 0.25rem;
    align-items: center;
    color: $secondary-700
  }

  .details {
    display: flex;
    align-items: center;
    color: $secondary-600;

    &.critical {
      color: $error-600;
    }
  }

  .notes {
    display: inline-block;
    max-width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .indicatorIcons {
    display: inline-flex;
    gap: 0.375rem;
    margin-right: 0.375rem;

    .icon {
      margin-left: 0;
      color: $secondary-300;

      &.active {
        color: $secondary-700;
      }
    }
  }

  .hints {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    .icon {
      margin-left: 0rem;
      font-size: 1.25rem;
      color: $secondary-300;

      &.active {
        color: $secondary-700;
      }
    }
  }
}
