$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.calendarContainer {
  position: absolute;
  display: flex;
  padding: 1rem;
  z-index: 2;
  background-color: white;
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.1), 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
  border-radius: 8px;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  .presets {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-down(md) {
      flex-direction: row;
      justify-content: flex-start;
      gap: 1rem;
      flex-wrap: wrap;

      .presetItem {
        border: 1px solid $secondary-400;
        padding: 0.5rem 1rem;
        width: fit-content;
      }
    }

    .presetItem {
      border-radius: $border-radius-sm;
    }
  }

  .actionButtons {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    margin-top: 1rem;
  }
}
