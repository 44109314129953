$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.requirementsSection {
  margin-top: 2rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: column;

  .resetButton {
    font-size: 1rem;
    font-weight: 500;
    color: $secondary-900;
    padding: 0;
  }
}

.flexRow {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.spaceBetween {
  justify-content: space-between;
  width: 100%;
}

.clockIcon {
  color: $warning-600;
}

.exclamationMarkIcon {
  color: $error-600;
}
