$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.additionalInfo {
  display: block;
  margin-bottom: 1.5rem;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $secondary-600;
}

.emailInputRow {
  display: flex;
  width: 100%;
  gap: 1rem;
}

.emailInput {
  width: 60%;
}

.list {
  padding-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.errorMessage {
  color: $error-600;
  display: flex;
  gap: 0.25rem;
  font-size: 0.875rem;
  font-weight: 400;
}

.sectionItemContent {
  margin-top: 2rem;
}

.gridRow {
  display: grid;
  grid-template-columns: 1fr 2fr 0.6fr;
  width: 60%;
  gap: 1rem;
}
