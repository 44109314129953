$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@use 'sass:math';
@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

$ITEM_HORIZONTAL_GAP: 3rem;
$ITEM_HORIZONTAL_QUARTER_GAP: math.div($ITEM_HORIZONTAL_GAP, 4);

.infoItem {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0.375rem 0.75rem;
  margin: 0 0.75rem;
  position: relative;

  &:first-child {
    margin-left: 0;
    padding-left: 0;
  }

  &--is-hoverable {
    cursor: pointer;
    border-radius: 8px;

    &:hover {
      background-color: $secondary-100;
    }
  }

  &:not(:first-child):after {
    content: '';
    position: absolute;
    top: 50%;
    left: -0.75rem;
    transform: translateY(-50%);
    height: 50%;
    width: 1px;
    background-color: $secondary-200;
  }

  .label {
    color: $secondary-600;
    font-weight: 400;
  }

  .value {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 400;
  }
}
