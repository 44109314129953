$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import '@skiwo/styles/global/variables.scss';

.actionButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  gap: 2rem;

  .buttonsWrapper {
    display: flex;
    gap: 1rem;
  }

  .cancelButton {
    border-color: $error-600;
    color: $error-600;

    &:hover {
      border-color: $error-600;
      background-color: $error-600;
      color: white;
    }
  }
}
