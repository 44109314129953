$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.avatar {
  position: relative;

  .activeIcon {
    position: absolute;
    right: 0.125rem;
    bottom: 0.125rem;
    width: 1rem;
    height: 1rem;
    color: $success-600;
    background-color: $white;
    border-radius: 50%;
  }

  img {
    height: 1.5rem;
    width: 1.5rem;
    object-fit: cover;
    overflow: hidden;
    border-radius: 50%;
    background-color: white;

    &.medium {
      height: 2.5rem;
      width: 2.5rem;
    }

    &.large {
      height: 4rem;
      width: 4rem;
    }
  }
}
