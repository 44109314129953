$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.informationSection {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .flexRow {
    display: flex;
    gap: 1.5rem;
  }

  .dates {
    display: flex;
    gap: 2rem;
    flex-direction: column;
  }

  .date {
    display: flex;
    gap: 2rem;
  }

  .languageSelector {
    width: 50%;
  }

  .dateItemWrapper {
    display: grid;
    grid-template-columns: 1fr 0.5fr 0.5fr 0.1fr 0.8fr;
    gap: 1.5rem;

    .button {
      margin-top: 1.75rem;
      height: 3.375rem;
    }
  }

  .timePicker {
    width: 5.75rem;
  }

  .category,
  .subject {
    width: 100%;
  }

  .errorMessage {
    display: flex;
    align-items: flex-end;
    gap: 0.25rem;
    font-size: 0.875rem;
    font-weight: 400;
    color: $error-600;
    padding-top: 0.5rem;

    .errorMessageIcon {
      font-size: 1.25rem;
    }
  }

  .warningBanner {
    padding-top: 1.5rem;
  }

  :global(.alert) {
    margin-bottom: 0;
  }
}
