$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

$gap: 1.5rem;

.title {
  font-size: 0.875rem;
  font-weight: 700;
  color: $secondary-900;
}

.hint {
  font-size: 0.875rem;
  font-weight: 400;
  color: $secondary-600;
}

.gridRow {
  display: grid;
  gap: $gap;
}

.postcodeRow {
  grid-template-columns: 35% auto;
}

.evenRow {
  grid-template-columns: repeat(2, 1fr);
}

.contactRow {
  grid-template-columns: 50% 15% 1fr;
}

.addressRow {
  grid-template-columns: 1fr max-content;

  .addAddress {
    background-color: $white;
  }
}

.videoWrapper {
  background-color: $white;
  padding: $gap;
  border-radius: 0.5rem;
  border: 1px solid $secondary-400;
}

.savedAddresses {
  font-weight: 500;
}
