$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.filesContainer {
  margin-top: 1.5rem;
  height: 100%;
}

.fileList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.titleContainer {
  display: flex;
  font-size: 0.875rem;
  line-height: 1.25rem;

  .passwordProtected {
    margin-right: 0.5rem;
  }

  .title {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .ocrBadge {
    border: 1px solid $secondary-300;
    border-radius: $border-radius-sm;
    font-size: 0.875rem;
    padding: 0.125rem 0.25rem;
    margin-left: 0.5rem;
  }
}

.fileSubtitle {
  display: flex;
  gap: 0.25rem;
  line-height: 1.25rem;
}

.noFiles {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1.5rem;
  padding: 0.75rem 1.5rem;
  min-height: 10rem;
  background-color: white;
  border: 1px solid $secondary-200;
  height: 100%;
  border-radius: $border-radius-md;
}

.icon {
  width: 1.25rem;
  margin-left: -0.125rem;
}
