$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.sectionLabel {
  display: inline-block;
  color: $secondary-600;
  font-size: 0.875rem;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
}

.customers {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;

  .option {
    border: 1px solid $secondary-400;
    border-radius: 0.5rem;
    margin: 0;
    padding: 0.75rem 1rem;
    background: transparent;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &:hover {
      background-color: $secondary-100;
    }
    &:focus {
      box-shadow: 0px 0px 0px 2px $secondary-900;
    }

    .infoDetails {
      display: flex;
      flex-direction: column;
    }
    .subtitle {
      color: $secondary-600;
    }
  }
}
