$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.customerDetails {
  border: 1px solid $secondary-200;
  padding: 1.25rem;
  border-radius: 0.5rem;

  hr {
    border-color: $secondary-200;
    opacity: 1;
  }

  .row {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .infoItem {
    width: 33.333%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &:not(:first-child) {
      &:before {
        position: absolute;
        content: '';
        display: block;
        width: 1px;
        height: 50%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: $secondary-200;
      }

      padding-left: 2rem;

      &:before {
        display: block;
      }
    }

    &:not(:last-child) {
      padding-right: 2rem;
    }

    .input {
      width: 100%;
    }

    .data {
      font-weight: 400;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .title {
      color: $secondary-600;
    }

    .value {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .iconWrapper {
      width: 2.75rem;
      height: 2.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .departmentInfoItem {
    width: 66.666%;
    padding-right: 0;
    padding-left: 2rem;
    position: relative;

    .button {
      width: 100%;
    }

    &:before {
      position: absolute;
      content: '';
      display: block;
      width: 1px;
      height: 50%;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: $secondary-200;
    }
  }

  .departmentSearch {
    width: 100%;
    padding-right: 1rem;
  }
}

.listItemInfo {
  color: $secondary-200;
}

.errorMessage {
  margin-top: 0.25rem;
  color: $error-600;
  display: flex;
  gap: 0.25rem;
  font-size: 0.875rem;
  font-weight: 400;
}
