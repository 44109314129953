$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.skeleton {
  display: flex;
  padding: 1.5rem;
  border: 1px solid $secondary-200;
  border-width: 1px 1px 0 0;
  height: 10rem;

  .box {
    height: 1rem;
    min-height: 0.5rem;
    border-radius: $border-radius-sm;
  }

  .tag {
    height: 1.5rem;
    min-height: 0.5rem;
    border-radius: 3.125rem;
  }

  .details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    padding-left: 0.75rem;
  }

  .circles {
    gap: 0.5rem;

    & > * {
      content: '';
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
    }
  }
  .flexRow {
    padding-top: 1rem;
    display: flex;
    gap: 0.5rem
  }
}
