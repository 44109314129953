$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

$radius: 0.25rem;

.availabilityTimeline {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

.timeline {
  overflow-x: auto;
  width: 100%;
}

.timeLabels {
  display: flex;
  width: 100%;
  font-weight: 400;
}

.timeLabel {
  font-size: 0.75rem;
  color: $secondary-400;
  position: relative;
  width: 6.25rem;
  flex-shrink: 0;
}

.timeGridWrapper {
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: 0.125rem;
}

.timeGrid {
  position: relative;
  display: flex;
  width: 100%;
}

.timeSlot {
  min-width: 6.25rem;
  border-right: 1px solid $secondary-200;
  border-top: 1px solid $secondary-200;
  border-bottom: 1px solid $secondary-200;
  background-color: #fff;
  min-height: 2.875rem;
}

.timeSlot:first-child {
  border-left: 1px solid $secondary-200;
  border-radius: $radius 0 0 $radius;
}

.timeSlot:last-child {
  border-radius: 0 $radius $radius 0;
}

.time {
  position: absolute;
  left: 0;
}

.meetingsContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.meetingBlock {
  position: absolute;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  background-color: $secondary-100;
  color: $secondary-900;
  border: 1px solid $secondary-300;
  border-radius: $radius;
  font-size: 0.875rem;
  font-weight: 400;
  height: 2.375rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &--default {
    background-color: $secondary-100;
    border: 1px solid $secondary-300;
  }
  &--green {
    background-color: $success-300;
    border: 1px solid $success-500;
  }
  &--selected {
    background-color: $info-400;
    opacity: 0.75;
    border: none;
    z-index: 1;
  }
  &--gray {
    background-color: $secondary-300;
  }
}

.legend {
  display: flex;
  gap: 1.5rem;
  align-items: center;
  margin-top: 1rem;

  .legendItem {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  .legendPill {
    width: 2rem;
    height: 0.5rem;
    border-radius: 0.5rem;

    &--default {
      background-color: $secondary-100;
      border: 1px solid $secondary-300;
    }

    &--green {
      background-color: $success-300;
      border: 1px solid $success-500;
    }

    &--gray {
      background-color: $secondary-300;
      border: 1px solid $secondary-300;
    }

    &--selected {
      background-color: $info-400;
      border: 1px solid $info-400;
    }
  }

  .legendTitle {
    font-size: 0.875rem;
    font-weight: 400;
    color: $secondary-700;
  }
}
