$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.sellerItem {
  width: 100%;
  padding: 1.25rem 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid;
  font-size: 1rem;
  background-color: $white;
  border-color: $secondary-200;

  .topSection {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }

  .bottomSection {
    margin-top: 1rem;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    .reasonLabel {
      color: $secondary-600;
    }
    .reason {
      color: $secondary-900;
    }

    .deadlinesWrapper {
      display: flex;
      gap: 1rem;
    }

    .deadline {
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: $secondary-200;
      border-radius: 0.5rem;
      padding: 0.75rem 1rem;
    }

    .deadlineTitle {
      color: $secondary-600;
    }

    .deadlineDate {
      color: $secondary-900;
    }

    .requested {
      font-size: 0.875rem;
      color: $secondary-600;
    }

    .bottomActions {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .approveActions {
        display: flex;
        gap: 0.5rem
      }
    }
  }

  &.selected {
    background-color: $white;
    border-color: transparent;
    box-shadow: 0 0 0 2px $secondary-900;
  }

  &.clickable {
    cursor: pointer;
  }

  .titleContainer {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .subtitle {
      display: flex;
      flex-wrap: wrap;
      gap: 0.25rem 1rem;
      font-size: 0.875rem;
      color: $secondary-600;

      .subtitleItem {
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }
    }
  }

  .selectedIndicator {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    border: 2px solid $secondary-400;
    width: 1.25rem;
    height: 1.25rem;
    min-width: 1.25rem;
    min-height: 1.25rem;
    border-radius: 50%;

    &.selectedCheckBox {
      background-color: $secondary-900;
      border-color: $secondary-900;
    }

    .selectedIcon {
      font-size: 0.75rem;
      color: white;
    }
  }

  .rightSection {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-left: auto;
  }
}
