$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import '@skiwo/styles/global/variables.scss';

.container {
  height: 100vh;
  width: 100%;
  background-color: $background-color;

  .row {
    height: 100%;
  }

  .imageView {
    padding: 0;

    @include media-breakpoint-down(lg) {
      display: none;
    }

    .heroImage {
      width: 100%;
      height: 100%;
      background-image: url('./auth-hero.png');
      background-size: cover;
    }
  }

  .formContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    .formContent {
      max-width: 31rem;
      border-radius: $border-radius-lg;
      box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
    }
  }
}
