$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.toggle {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;

  .chevron {
    font-size: 0.75rem;
    color: $secondary-600;
  }
}

.isLocked {
  opacity: 0.7;
}

.lockedToggle {
  cursor: default;
}

.success {
  color: $success-300;
}

.error {
  color: $error-300;
}

.info {
  color: $info-300;
}

.warning {
  color: $warning-300;
}

.primary {
  color: $primary-300;
}

.neutral {
  color: $secondary-300;
}
