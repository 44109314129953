$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.emptyTable {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 300;
  letter-spacing: 0.0875rem;
  color: $secondary-700;

  span {
    line-height: 2rem;
  }

  img {
    margin-bottom: -0.625rem;
  }

  .description {
    color: $secondary-400;
    line-height: 2rem;
    margin-top: 0.5rem;
  }

  button {
    margin-top: 2rem;
    font-size: 1rem !important;
  }
}
