$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.informationSection {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .radioOptionsWrapper {
    padding: 1.25rem;
    background: $secondary-100;
    border-radius: 0.5rem;
  }

  .alternativeLanguageSelector {
    margin-top: 3rem;
  }

  .flexRow {
    display: flex;
    gap: 1.5rem;
  }

  .languageSelector {
    width: 50%;
  }

  .noSourceLanguageCheckbox {
    margin-top: 1.5rem;
  }

  .fileSection {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .fileList {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .filePassword {
      max-width: 10rem;
    }
  }

  .wordsCountInput {
    width: 80%;
  }
  .deadline {
    width: 20%;
  }

  .subject {
    width: 50%;
  }
}
