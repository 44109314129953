$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.skeleton {
  width: 15rem;
}

.description {
  display: block;
  margin-top: 1rem;
}

.values {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  gap: 0.5rem;
}

.descriptionValues {
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin-top: 1rem;
}

.descriptionOldValueWrapper {
  width: 55%;
  display: flex;
  align-items: center;
}

.descriptionOldValue {
  max-width: 100%;
  width: 90%;
  text-align: left;
}

.descriptionValue {
  width: 45%;
  text-align: left;
}

.descriptionArrow {
  width: 10%;
  display: flex;
  justify-content: center;
}

.descriptionArrow,
.arrow {
  font-size: 0.75rem;
}
