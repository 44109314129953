$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.fileUploader {
  margin-top: 2rem;
}

.returnAddressLabel {
  display: block;
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-top: 2rem;
  font-weight: 600;
}

.coverLetterHasAddressCheckbox {
  margin: 2rem 0;
}

.returnAddressFormWrapper {
  margin-top: 2rem;

  .returnAddressFormFirstRow {
    display: flex;
    gap: 2rem;
    margin-bottom: 2rem;

    .recipientName {
      width: 40%;
    }
    .enterpriseName {
      width: 60%;
    }
  }
}

.coverLetterLabel,
.coverLetterErrorMessage {
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
}

.coverLetterLabel {
  color: $secondary-900;
  margin-bottom: 0.25rem;
}

.coverLetterErrorMessage {
  color: $error-600;
  display: flex;
  gap: 0.25rem;
}
