$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.interpretersSection {
  padding: 1.5rem;
  border-radius: 0.5rem;
  background-color: $secondary-50;
  margin-top: 3rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: $secondary-900;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
  margin: 0;

  svg {
    width: 1rem;
    height: 1rem;
  }
}

.infoRow {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background-color: $white;
  margin-top: 2rem;
}

.toggleRow {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.dropdownBtn {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  background: transparent;
  border: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $secondary-900;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: auto;
  }
}

.btnIconRight {
  width: 0.75rem;
  height: 0.75rem;
}

.btnIconLeft {
  width: 1.25rem;
  height: 1.25rem;
}

.switchLabel {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
