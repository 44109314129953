$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import '@skiwo/styles/global/variables.scss';

.content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
}

.radioOptions {
  padding-left: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.textArea {
  margin-top: 1rem;
}

.list,
.listTitle {
  font-size: 1.125rem;
  font-weight: 400;
  color: $secondary-900;
}

.listTitle {
  display: block;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
}
