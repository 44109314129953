$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

a.menuItem {
  display: flex;
  padding: 0.5rem;

  &:visited {
    color: $secondary-700;
    background-color: $white;
  }

  &:active {
    color: unset;
  }
  &:focus-visible {
    outline: 2px solid crimson;
  }

  &:focus,
  &:hover {
    background-color: $secondary-50;
  }

  &.disabled {
    color: $secondary-300;

    .description {
      display: block;
      color: $secondary-300;
    }

    .icon {
      color: $secondary-300;
    }
  }

  & > :not(:first-of-type) {
    margin-left: 0.5rem;
  }

  .icon {
    color: $secondary-700;
  }

  .name {
    display: block;
    font-weight: 600;
  }

  .description {
    display: block;
    color: $secondary-500;
    word-wrap: normal;
    white-space: normal;
  }
}
