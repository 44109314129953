$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.deleteCustomerModal {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .bold {
    font-weight: 600;
  }

  .reason {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .reasonTitle {
      color: $secondary-900;
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 2rem;
    }

    .reasonDescription {
      color: $secondary-600;
      font-size: 1.125rem;
      line-height: 1.75rem;
      font-weight: 400;
    }
  }

  .reasonOptions {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-left: 0.75rem;
    margin-top: 2rem;
  }

  .otherReason {
    margin-top: 0.5rem;
  }

  .actionButtons {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    justify-content: flex-end;

    button {
      max-width: 10.875rem;
    }
  }
}
