$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.headerWrapper {
  &--sm {
    margin-bottom: 1rem;
  }

  &--md {
    margin-bottom: 1.5rem;
  }
}

.header {
  display: flex;
  justify-content: space-between;
}

.iconTitleWrapper {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: $secondary-700;
}

.title {
  font-size: 1.125rem;
  font-weight: 600;
  color: $secondary-900;
}

.description {
  display: block;
  margin-top: 0.5rem;
  font-weight: 400;
}
