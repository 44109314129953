$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.taskSection {
  padding: 2rem;
  overflow-y: scroll;

  .taskHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .taskTitle {
      display: flex;
      gap: 0.5rem;
      font-size: 2.25rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-weight: 600;
    }

    .taskDetails {
      display: flex;
      gap: 0.5rem;
      align-items: center;

      .secondaryValue {
        color: $secondary-600;
        font-size: 0.875rem;
        font-weight: 400;
      }
    }
  }

  .taskId {
    display: flex;
    gap: 0.5rem;
    color: $secondary-600;
    font-weight: 400;
  }

  .taskInfo {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;

    .secondaryValue {
      color: $secondary-600;
      font-weight: 400;
    }

    .label {
      color: $secondary-600;
      font-weight: 400;
    }

    .value {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    .createSubtask {
      margin-top: 1.5rem;
      width: fit-content;
    }
  }

  .subtasks {
    margin-top: 1.5rem;

    .header {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    .subtaskList {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      margin-top: 1.5rem;
      font-weight: 400;
    }

    .subtaskListEmptyState {
      width: 100%;
      text-align: center;
      padding: 2.375rem;
      border: 1px solid $secondary-200;
      border-radius: 0.5rem;
    }

    .subtaskItemInfo {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
}
