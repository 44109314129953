$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.createMenu {
  padding-left: 1.5rem;
  font-weight: 400;

  .label {
    display: block;
    color: $secondary-500;

    &:not(:first-child) {
      margin: 1rem 0;
    }
  }

  hr {
    opacity: 1;
    color: $secondary-200;
  }

  :global(.dropdown-menu) {
    margin-top: 7px;
    border-radius: 0.75rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 1rem;
    box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.05), 0px 20px 24px -4px rgba(16, 24, 40, 0.05);

    :global(.dropdown-item) {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      border-radius: $border-radius-sm;

      svg {
        height: 1rem;
        width: 1.5rem;
      }
    }
  }
}
