$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.riskFactors {
  margin-top: 3rem;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  display: flex;
  gap: 2rem;
}

.riskFactors--risky {
  background-color: $error-200;
}
.riskFactors--risk_accepted {
  background-color: $warning-200;
}

.text {
  font-weight: 400;
}

.title {
  color: $secondary-600;
}

.exclamationIcon--risky,
.exclamationIcon--risk_accepted {
  margin-right: 0.5rem;
}

.exclamationIcon--risky {
  color: $error-600;
}
.exclamationIcon--risk_accepted {
  color: $warning-600;
}

.riskList {
  display: flex;
  gap: 0.5rem;
}

.icon {
  color: $secondary-700;
}

.riskItem {
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  background: $white;
}
