$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.skeleton {
  td {
    vertical-align: middle;
  }

  .indicatorSkeleton {
    position: relative;
    padding-left: calc($table-cell-padding-x + $row-indicator-width);

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      display: inline;
      width: $row-indicator-width;
      border-top-left-radius: $border-radius-md;
      border-bottom-left-radius: $border-radius-md;
      height: 100%;
      background-color: $secondary-200;
    }
  }
}
