$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .infoCard {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.1), 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
    border-radius: $border-radius-md;
    color: $secondary-900;

    .id {
      font-size: 0.875rem;
      color: $secondary-400;
      font-weight: 400;
    }

    .name {
      font-weight: 700;
      font-size: 1.125rem;
    }

    .contactInfo {
      display: flex;
      flex-direction: column;
      font-size: 0.875rem;
      font-weight: 400;
    }
  }

  .textInfo {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .heading {
      font-weight: 500;
      font-size: 1.125rem;
    }

    .description {
      font-weight: 400;
      color: $secondary-600;
      font-size: 0.875rem;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
}
