$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.orderSection {
  padding: 2rem;
  height: 100%;
  overflow-y: scroll;
}

.mainInfo {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.flexRow {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.link,
.date {
  font-weight: 400;
  color: $secondary-600;
}

.header {
  font-size: 2rem;
  font-weight: 600;
  color: $secondary-900;
}

.category {
  font-size: 1.125rem;
  font-weight: 400;
}

.infoItems {
  display: flex;
}

.timeInterval {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.requirementsSection {
  margin-top: 2rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
}

.cancelBannerText {
  font-size: 0.875rem;
  font-weight: 400;
  color: $secondary-900;

  &--bold {
    font-weight: 600;
  }
}
