$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;

  .forgotPassword {
    padding-right: 0;
  }
}
