$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.trafficLightIndicator {
  content: '';
  display: flex;
  align-items: center;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;

  &.green {
    background-color: $success-600;
  }
  &.yellow {
    background-color: $warning-600;
  }
  &.red {
    background-color: $error-600;
  }
}
