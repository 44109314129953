$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.header {
  color: $secondary-600;
  font-weight: 600;
  font-size: 0.875rem;
}

.body {
  --bs-table-striped-bg: #fff;
}

.rowName {
  font-weight: 600;
  color: $secondary-900;
}

.flexRow {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.inputTextRow {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: $secondary-600;
}

.input {
  width: 5rem;
}

.filesList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.rowNameFlex,
.fileItem {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.fileIcon {
  width: 10px;
  padding: 0 3px;
}

.fileLink {
  color: $secondary-900;
  padding: 0;
  font-weight: 500;
  text-decoration: underline;
}

.lastUpdatedDate,
.lastUpdatedAuthor {
  display: block;
  font-size: 0.875rem;
}

.lastUpdatedDate {
  color: $secondary-600;
}

.dateTag {
  font-size: 0.875rem;
}
