$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.subtitle {
  font-size: 1rem;
}

.videoSolutionDrawer {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  .item {
    display: flex;
    gap: 0.75rem;
    color: $secondary-900;

    .content {
      width: 100%;
    }

    .icon {
      width: 1.25rem;
      height: 1.25rem;
      padding-top: 0.25rem;
    }

    .title {
      display: block;
      font-size: 1.125rem;
      font-weight: 600;
      padding-bottom: 0.25rem;
    }

    .description {
      display: block;
      font-size: 1rem;
      font-weight: 400;

      .browsersWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        padding-top: 1.5rem;

        .browserItem {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 1rem;
          border-radius: 0.5rem;
          border: 1px solid $secondary-200;

          .browserItemContent {
            display: flex;
            gap: 0.75rem;
          }

          .downloadButton {
            color: $secondary-600;
            font-size: 0.875rem;
            padding: 0;
          }
        }
      }
    }
  }
}
