$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.goBack {
  position: absolute;
  top: 1rem;
  padding: 0 !important;
}

.title {
  font-size: 24px;
  font-weight: 600;
  color: $secondary-900;
  vertical-align: middle;
}

.subtitle {
  display: block;
  margin-top: 0.5rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.drawerTitleTag {
  padding-left: 8px;
}
