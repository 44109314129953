$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.successBannerContainer {
  display: flex;
  justify-content: center;
  background-color: $success-100;
  border-radius: $border-radius-md;
  height: 10rem;
  padding: 2.5rem 1.5rem;

  .innerBlockBackground {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    border-radius: $border-radius-md;
    width: 20rem;
    padding: 1.25rem;
    box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.15);

    .avatar {
      background-color: $secondary-200;
      border-radius: $border-radius-md;
      height: 2.5rem;
      width: 2.5rem;
    }

    .simulatedNames {
      padding: 0.5rem 0;

      .simulatedFirstName {
        display: block;
        background-color: $secondary-200;
        border-radius: $border-radius-md;
        width: 6rem;
        height: 0.5rem;
        margin-bottom: 0.5rem;
      }

      .simulatedLastName {
        display: block;
        background-color: $secondary-200;
        border-radius: $border-radius-md;
        width: 4rem;
        height: 0.5rem;
      }
    }

    .checkIcon {
      color: $success-600;
    }
  }
}
