@import './fonts.scss';
@import './variables.scss';

// Global overrides
$border-radius: $border-radius-sm;
$min-contrast-ratio: 3.1;
$spacer: 1rem;
$border-width: 1px;
$font-weight-base: 500;
$enable-caret: false;
$font-family-base: 'Poppins';
$body-color: $secondary-900;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

// Checkbox overrides
$form-check-input-checked-bg-color: $black;
$form-check-input-border: 1px solid $border-color;
$input-group-addon-padding-y: 2rem;

// Input overrides
$input-padding-y: 0.5rem;
$input-padding-x: 0.5rem;
$input-padding-y-lg: 0.875rem;
$input-padding-x-lg: 0.5rem;
$input-font-size: 0.875rem;
$input-font-size-lg: 1rem;
$input-font-weight: 400;
$form-label-font-size: 0.875rem;
$form-label-margin-bottom: 0.25rem;
$form-label-font-weight: 400;
$form-label-color: $secondary-900;
$input-border-color: $secondary-400;
$input-placeholder-color: $secondary-500;
$input-border-radius-lg: $border-radius-sm;
$input-group-addon-bg: none;
$input-disabled-bg: $secondary-300;
$input-disabled-border-color: $secondary-400;

// Form overrides
$form-text-font-size: 0.875rem;
$form-text-font-weight: 400;
$form-feedback-icon-invalid-color: $error-600;
$form-feedback-font-size: $form-text-font-size;
$enable-validation-icons: false;

// Alert overrides
$alert-border-radius: 0.5rem;
$alert-link-font-weight: 400;
$alert-bg-scale: -90%;

// Badge overrides
$badge-font-size: 1rem;
$badge-font-weight: $font-weight-base;
$badge-border-radius: 3.125rem;
$badge-padding-y: 0.125rem;
$badge-padding-x: 0.25rem;

// Tabs overrides
$nav-link-padding-y: 0.75rem;
$nav-link-padding-x: 0.75rem;
$nav-link-font-size: $font-size-base;
$nav-link-font-weight: $font-weight-base;
$nav-link-color: $secondary-600;
$nav-link-hover-color: $secondary-600;
$nav-link-disabled-color: $secondary-500;

$nav-pills-border-radius: $border-radius-md;
$nav-pills-link-active-color: $primary;
$nav-pills-link-active-bg: none;

// Navbar overrides
$navbar-padding-y: 0;
$navbar-padding-x: 2rem;
$navbar-light-color: $secondary-600;
$navbar-light-hover-color: $secondary-600;
$navbar-light-active-color: $primary;
$navbar-light-toggler-border-color: none;
$navbar-light-brand-color: $secondary-900;
$navbar-light-brand-hover-color: $secondary-900;

// Dropdown overrides
$dropdown-border-width: 0;
$dropdown-padding-y: 0;

// ListGroup overrides
$list-group-color: $secondary-900;
$list-group-border-width: 0;
$list-group-border-radius: 0;
$list-group-item-padding-y: 0.5rem;
$list-group-item-padding-x: 0.75rem;

$list-group-hover-bg: $secondary-100;
$list-group-active-color: $secondary-900;
$list-group-active-bg: $primary-200;

$list-group-action-color: $secondary-900;
$list-group-action-hover-color: $secondary-900;

$list-group-action-active-color: $secondary-900;
$list-group-action-active-bg: $secondary-100;

// Table overrides
$table-cell-padding-y: 0.75rem;
$table-cell-padding-x: 0.75rem;
$table-cell-vertical-align: center;
$table-cell-horizontal-align: left;
$table-th-font-weight: 400;
$table-striped-color: $secondary-900;
$table-striped-bg: $secondary-100;
$table-border-width: 0;
$table-striped-order: even;

// Skeleton overrides
$placeholder-opacity-max: 0.1;
$placeholder-opacity-min: 0;

// Tooltip overrides
$tooltip-font-size: 0.875rem;
$tooltip-max-width: 20rem;
$tooltip-color: $secondary-700;
$tooltip-bg: $white;
$tooltip-border-radius: $border-radius-md;
$tooltip-opacity: 1;
$tooltip-padding-y: 0.75rem;
$tooltip-padding-x: 0.75rem;
$tooltip-margin: 0.25rem;

$tooltip-arrow-width: 1rem;
$tooltip-arrow-height: 0.5rem;
// fusv-disable
$tooltip-arrow-color: null; // Deprecated in Bootstrap 5.2.0 for CSS variables
// fusv-enable

// Dropdown overrides
$dropdown-padding-x: 0.5rem;
$dropdown-padding-y: 0;
$dropdown-font-size: 0.875rem;
$dropdown-border-radius: $border-radius-md;
$dropdown-border-width: 0;
$dropdown-link-color: $secondary-700;
$dropdown-link-hover-color: $dropdown-link-color;
$dropdown-link-hover-bg: $secondary-100;
$dropdown-link-active-color: $secondary-700;
$dropdown-link-active-bg: $secondary-100;
$dropdown-item-padding-y: 0.75rem;
$dropdown-item-padding-x: 0.75rem;

// Modal overrides
$modal-inner-padding: 2rem;
$modal-footer-margin-between: 0.25rem;
$modal-content-color: $secondary-600;
$modal-content-border-width: 0;
$modal-content-border-radius: $border-radius-lg;
$modal-backdrop-bg: $secondary-400;
$modal-backdrop-opacity: 0.5;
$modal-header-border-width: $modal-content-border-width;
$modal-header-padding-y: $modal-inner-padding;
$modal-header-padding-x: $modal-inner-padding;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility
$modal-footer-border-width: $modal-header-border-width;
$modal-sm: 30rem;
$modal-lg: 40rem;
$modal-xl: 48.75rem;
$modal-xxl: 60rem;

// Switch overrides
$form-switch-color: rgba($secondary-900, 0.25);
$form-switch-width: 2.75rem;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>");
$form-switch-border-radius: 3.125rem;
$form-switch-transition: background-position 0.15s ease-in-out;
$form-switch-focus-color: $input-focus-border-color;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>");
$form-switch-checked-color: $component-active-color;
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>");
$form-switch-checked-bg-position: right center;

// Checkbox overrides
$form-check-input-width: 1.25rem;
$form-check-min-height: $font-size-base * $line-height-base;
$form-check-padding-start: calc($form-check-input-width + 0.5rem);
$form-check-label-color: $secondary-700;
$form-check-transition: background-color 0.15s ease-in-out;
$form-check-input-border: 1px solid $secondary-900;
$form-check-input-border-radius: $border-radius-sm;
$form-check-input-checked-color: $component-active-color;
$form-check-input-checked-bg-color: $secondary-900;
$form-check-input-checked-border-color: $form-check-input-checked-bg-color;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>");
$form-check-input-indeterminate-color: $component-active-color;
$form-check-input-indeterminate-bg-color: $secondary-900;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>");

// Drawer overrides
$offcanvas-padding-y: 2.5rem;
$offcanvas-padding-x: 3rem;
$offcanvas-horizontal-width: 51rem;
$offcanvas-title-line-height: 2rem;

// Breadcrumb overrides
$breadcrumb-divider-color: $secondary-500;
$breadcrumb-active-color: $secondary-500;
$breadcrumb-divider: quote('>');
$breadcrumb-divider-flipped: $breadcrumb-divider;

// Toast overrides
$toast-max-width: 25rem;
$toast-background-color: $secondary-900;
$toast-color: $white;
$toast-border-radius: $border-radius-md;
$toast-padding-x: 1rem;
$toast-padding-y: 1rem;
$toast-spacing: 0.5rem;

@import 'bootstrap/scss/bootstrap';

// Tooltip
.tooltip {
  margin: 0;

  .tooltip-inner {
    box-shadow: 0px 0px 8px -4px rgba(16, 24, 40, 0.05), 0px 10px 24px -4px rgba(16, 24, 40, 0.1);
    color: $secondary-700;

    span:not(:first-of-type) {
      display: block;
      color: $secondary-400;
    }
  }

  .tooltip-arrow {
    box-shadow: 0px 0px 8px -4px rgba(16, 24, 40, 0.05), 0px 10px 24px -4px rgba(16, 24, 40, 0.1);
  }
}

// Form switch
.form-switch {
  .form-check-input {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    height: 1.5rem;
    border: none;
    font-weight: 400;
    font-size: 0.875rem;

    &[type='checkbox']:not(:checked) {
      background-color: $secondary-300;
    }
  }

  &,
  .form-check-label {
    cursor: pointer;
  }

  &:has(.form-check-input:disabled) {
    &,
    .form-check-label {
      cursor: not-allowed;
    }
  }
}

// Form check
.form-check {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: 400;
  font-size: 0.875rem;

  .form-check-input {
    margin-top: 0;
    cursor: pointer;
  }
}

// Drawer
.offcanvas.offcanvas-end {
  width: 51rem;

  @include media-breakpoint-down(md) {
    width: 83%;
  }

  .offcanvas-header {
    padding-bottom: 1rem;
    display: flex;
    align-items: flex-start;
  }

  .offcanvas-body {
    padding-top: 1rem;
  }
}

.offcanvas.offcanvas-bottom {
  width: 100%;
  height: 90%;
  padding: 1rem;
  border-top-left-radius: $border-radius-lg;
  border-top-right-radius: $border-radius-lg;

  .offcanvas-header {
    padding: 0rem;
    padding-bottom: 1rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .offcanvas-body {
    padding: 0rem;
  }
}

// Buttons
.btn-link {
  color: $secondary-900;

  &:hover {
    color: $secondary-900;

    span {
      text-decoration: underline;
    }
  }
}

// breadcrumb
.breadcrumb-item {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;

  a {
    text-decoration: none;
    color: $secondary-900;
  }

  a:hover {
    color: $primary;
  }
}

@each $color, $value in $theme-colors {
  @if $color == primary {
    .btn-outline-primary {
      @include button-outline-variant(
        $value,
        $color-hover: $primary,
        $active-background: $primary-100
      );
    }
  } @else if $color == secondary {
    .btn-outline-secondary {
      color: $secondary-900;
      @include button-outline-variant(
        $secondary-400,
        $color-hover: $secondary-900,
        $active-background: $secondary-100,
        $active-border: $secondary-400
      );
    }
  } @else if $color == light {
    .btn-light {
      @include button-variant($white, $secondary-400);
    }
  }
}
