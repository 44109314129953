$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import '@skiwo/styles/global/base.scss';

.form {
  padding: 2rem;
  background-color: $white;
  border-radius: $border-radius-lg;

  @include media-breakpoint-down(sm) {
    padding: 2rem 1rem;
  }

  .content {
    margin-top: 3rem;

    & > :not(:first-child) {
      margin-top: 2rem;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;

    & > :not(:first-child) {
      margin-left: 3.5rem;
    }

    .heading {
      font-size: 1.5rem;
      font-weight: 700;
    }

    .illustration {
      width: 6rem;
      height: 6rem;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  .description {
    color: $secondary-600;
    font-weight: $form-text-font-weight;
    font-size: 1.125rem;
  }
}
