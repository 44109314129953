$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.statement {
  position: relative;
  background-color: $secondary-50;
  padding: 1.5rem;
  padding-bottom: 2.5rem;
  margin-top: 3rem;

  .cardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.125rem;
  }

  .cardTitle {
    display: flex;
    align-items: center;
    gap: 0.375rem;
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0;

    svg {
      height: 1rem;
      width: 1rem;
    }
  }

  .tag {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .settingsRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
    padding: 1.5rem;
    padding-top: 1.25rem;
    background-color: $white;
    border-radius: 0.5rem;
  }

  .settings {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
  }

  .date {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    gap: 1rem;
    align-items: end;
  }

  .submitRow {
    text-align: right;
  }
  .errorBanner {
    margin-top: 1.5rem;
  }
}

.readyForFinanceModalContent {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .errorRiskBanner {
    div {
      margin: 0;
    }
  }
}
