$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.editOrder {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .inputRow,
  .wordCountAccessRow {
    display: flex;
    gap: 2rem;

    > div,
    > button {
      width: 100%;
    }

    input::placeholder {
      color: $secondary-900;
      opacity: 1;
    }
  }

  .wordCountAccessRow {
    > div {
      flex-grow: 0;
      width: 14rem;
    }

    > div:nth-child(2) {
      flex-grow: 1;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }

  .loggedOutContainer {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .phoneInputs,
    .nameInputs {
      display: flex;
      gap: 2rem;

      & > * {
        width: 100%;
      }
    }

    .phoneInputs {
      & > :first-child {
        max-width: 14rem;
      }
    }

    hr {
      margin: 0;
      color: $secondary-200;
    }

    .logoutTitle {
      color: $secondary-900;
      font-size: 1.125rem;
      margin: 0;
    }
  }
  .errorText {
    padding-top: 0.25rem;
    display: flex;
    gap: 0.25rem;
    color: $error-600;
    font-size: 0.875rem;
    font-weight: 400;

    svg {
      height: 1rem;
      width: 1rem;
    }
  }
}
