$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import '@skiwo/styles/global/variables.scss';

.body {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1.25rem 1rem;
  border: 1px solid $secondary-200;
  border-radius: 0.5rem;
}

.hr {
  height: 1px;
  background-color: $secondary-200;
  margin: 0;
  padding: 0;
}

.rowItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 1rem;

  .rowItemTitle {
    color: $secondary-600;
  }

  .rowItemBody {
    color: $secondary-900;
  }
}

.formFooter {
  display: flex;
  justify-content: flex-end;
  margin-top: 2.5rem;
  gap: 1rem;
}
