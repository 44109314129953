$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

.container {
  .infoCard {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border-radius: 0.5rem;
    color: $secondary-900;
    background-color: $secondary-100;

    .id {
      font-size: 0.875rem;
      color: $secondary-600;
      font-weight: 400;
    }

    .name {
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1.75rem;
    }

    .contactInfo {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      font-size: 0.875rem;
      font-weight: 400;
    }
  }

  .emptyState {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 0.75rem;
    padding-block: 2.5rem;

    svg {
      font-size: 2.5rem;
      color: $secondary-200;
    }
    p {
      color: $secondary-500;
      font-weight: 400;
      margin: 0;
    }
  }

  .noteTextfield {
    margin-bottom: 0.625rem;

    textarea {
      height: 7.75rem;
    }
  }

  .postButtons {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    padding-top: 2.5rem;
  }
}
