$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

:global(.modal-content) {
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.1), 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
}

div.modal {
  .modalHeader {
    padding-bottom: 0;
    color: $secondary-900;
  }

  .modalBody {
    padding-bottom: 0;

    &.hasDescription {
      padding-top: 0;

      .description {
        font-weight: 400;
        color: $secondary-600;
        padding-top: 0.5rem;
        margin-bottom: 2rem;
      }
    }
  }

  .modalFooter {
    display: flex;
    gap: 1rem;
  }

  .noModalFooter {
    padding-top: 0;
  }
}
