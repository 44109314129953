$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  hr {
    margin: 0;
  }
}

.formFooter {
  display: flex;
  gap: 1rem;
  margin: 0 0 0 auto;
}

.inputRow {
  display: flex;
  gap: 1.5rem;

  > * {
    width: 100%;
  }

  .defaultIndicator {
    border: 1px solid $secondary-300;
    border-radius: $border-radius-sm;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    background-color: $white;
  }
}

.sectionTitle {
  display: block;
  font-size: 1.125rem;
  font-weight: 600;
  color: $secondary-900;
  padding-top: 0.5rem;
  padding-bottom: 1.25rem;
}

.pricingTemplates {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
