$primary: #ff5b24 !default;
$primary-100: #ffefe9 !default;
$primary-200: #ffe1d7 !default;
$primary-300: #ffc3af !default;
$primary-400: #ffa587 !default;
$primary-500: #ff8860 !default;
$primary-600: #ff6a38 !default;
$primary-700: #ff5b24 !default;
$primary-800: #ba421a !default;
$primary-900: #8b3214 !default;

@import '@skiwo/styles/global/variables.scss';

.editSubtask {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-bottom: 8.25rem;

  .overview {
    background-color: $secondary-100;
    border-radius: $border-radius-md;
    padding: 1rem;

    .id {
      color: $secondary-600;
      font-size: 0.875rem;
      font-weight: 400;
    }

    .title {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 1.125rem;
      font-weight: 600;
      color: $secondary-900;
    }

    .description {
      display: block;
      color: $secondary-900;
      font-size: 0.875rem;
      font-weight: 400;
      margin-top: 1rem;
    }
  }

  .detailItemList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .selectedIndicator {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    border: 2px solid $secondary-400;
    width: 1.25rem;
    height: 1.25rem;
    min-width: 1.25rem;
    min-height: 1.25rem;
    border-radius: 50%;

    &.selected {
      background-color: $secondary-900;
      border-color: $secondary-900;
    }

    .selectedIcon {
      font-size: 0.75rem;
      color: white;
    }
  }

  .inputRow {
    display: flex;
    gap: 2rem;
  }

  .emptyState {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.25rem;
    color: $secondary-900;
    padding-top: 6rem;
  }

  .emptyStateIcon {
    font-size: xx-large;
    color: $secondary-600;
  }
}

.tabsContainer {
  border-bottom: 1px solid $secondary-200;
}

.actions {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 2.5rem 3rem 2.5rem 0;
  width: 100%;
  border-top: 1px solid $secondary-200;
  background-color: $white;
}

.detailItem {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  padding: 1.25rem 1.5rem;
  border-radius: 0.5rem;
  border: 1px solid;
  font-size: 1rem;
  cursor: pointer;

  &.primary {
    background-color: $white;
    border-color: $secondary-200;
  }

  &.selected {
    background-color: $white;
    border-color: transparent;
    box-shadow: 0 0 0 2px $secondary-900;
  }

  .titleContainer {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .rightSection {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    margin-left: auto;
  }
}
